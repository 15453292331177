import classNames from "classnames";

export default function Socials({ className }: { className?: string }) {
    return (
        <ul className={classNames("flex items-center gap-4", className)}>
            <li>
                <a href="tg://resolve?domain=Hillel1408">
                    <svg width="18" height="15" className="opacity-50 hover:opacity-100 duration-300">
                        <path
                            d="M1.6385 6.39796C1.6385 6.39796 8.92164 3.27232 11.4476 2.17171C12.4159 1.7315 15.6996 0.322711 15.6996 0.322711C15.6996 0.322711 17.2152 -0.293581 17.0889 1.20319C17.0467 1.81954 16.71 3.97666 16.3732 6.30992C15.868 9.6117 15.3207 13.2216 15.3207 13.2216C15.3207 13.2216 15.2365 14.2342 14.5208 14.4102C13.8051 14.5863 12.6263 13.794 12.4159 13.6178C12.2474 13.4858 9.25842 11.5047 8.16385 10.5362C7.86914 10.272 7.53237 9.74379 8.20591 9.12744C9.72151 7.67466 11.5317 5.86971 12.6263 4.72511C13.1315 4.19679 13.6367 2.96415 11.5317 4.46092C8.54275 6.61809 5.59581 8.64318 5.59581 8.64318C5.59581 8.64318 4.92221 9.08339 3.65925 8.68717C2.39623 8.29101 0.922766 7.7627 0.922766 7.7627C0.922766 7.7627 -0.0875522 7.10236 1.6385 6.39796Z"
                            fill="white"
                        />
                    </svg>
                </a>
            </li>
            <li>
                <a href="https://www.fl.ru/users/balzary/portfolio/" target="blanck">
                    <svg width="18" height="15" className="opacity-50 hover:opacity-100 duration-300">
                        <path
                            d="M0.726313 0.0703125H7.85522V2.73633H3.43139V5.34375H7.85522V7.9707H3.43139V14.4355H0.726313V0.0703125ZM10.2966 0.0703125H13.031V11.8281H17.0154V14.4355H10.2966V0.0703125Z"
                            fill="white"
                        />
                    </svg>
                </a>
            </li>
        </ul>
    );
}
